import React from "react";
import "../Styles/LandingPage/HeroSection.css";
import html from "../Assets/LandingPage/html.png";
import css from "../Assets/LandingPage/css.png";
import js from "../Assets/LandingPage/js.png";
import nodejs from "../Assets/LandingPage/nodejs.png";
import kotlin from "../Assets/LandingPage/kotlin.png";
import swift from "../Assets/LandingPage/swift.png";
import bootstrap from "../Assets/LandingPage/bootstrap.png";
import react from "../Assets/LandingPage/react.png";
import land_fire from "../Assets/LandingPage/land_fire.png";
import img1 from "../Assets/LandingPage/img1.png";
import img2 from "../Assets/LandingPage/img2.png";
import img3 from "../Assets/LandingPage/img3.png";
import img4 from "../Assets/LandingPage/img4.png";
import img5 from "../Assets/LandingPage/img5.png";
import img6 from "../Assets/LandingPage/img6.png";
import img7 from "../Assets/LandingPage/img7.png";
function HeroSection() {
  return (
    <div>
      <div id="land_checked_img" align="center">
        <div id="land_hero_bg">
          <div className="container d-lg-block d-md-none d-none">
            <div id="land_1st_line">
              <div className="row">
                <div
                  className="col-6"
                  align="left"
                  style={{ paddingLeft: "9.5rem" }}
                >
                  <img src={css} />
                </div>
                <div
                  className="col-6"
                  align="end"
                  style={{ paddingRight: "10rem" }}
                >
                  <img src={kotlin} />
                </div>
              </div>
            </div>
            <div id="land_seat_ltd" align="center">
              <img src={land_fire} alt="" /> Only Limited Slots
            </div>
            <div id="land_2nd_line">
              <div className="row" align="center">
                <div className="col-md-1">
                  <img src={html} alt="" width="100%" />
                </div>
                <div className="col-md-10" id="land_hero_text">
                  <h1>
                    Best Full Stack Development Training Institute in Bangalore
                  </h1>
                </div>

                <div className="col-md-1">
                  <img src={swift} alt="" width="100%" />
                </div>
              </div>
            </div>
            <p id="land_sub_text">
              With 100% Placement Assured In Top Tech Companies
            </p>

            <div id="land_3rd_line">
              <div className="row">
                <div className="col-2">
                  <div id="bootstrap_icon">
                    <img src={bootstrap} alt="" />
                  </div>
                </div>
                <div className="col-8">
                  <div id="land_cont_btn">
                    <a
                      href="javascript:void(Tawk_API.toggle())"
                      className="text-decoration-none text-white"
                    >
                      <button className="btn">
                        <div> Consult Us Now</div>

                        <div className=" d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19.1618 12L12.9547 5.7929L11.5405 7.2071L16.3334 12L11.5405 16.7929L12.9547 18.2071L19.1618 12ZM13.5119 12L7.30483 5.7929L5.89062 7.2071L10.6835 12L5.89062 16.7929L7.30483 18.2071L13.5119 12Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-2" align="left">
                  <div id="react_icon">
                    <img src={react} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div id="land_features" className="row" align="center">
              <div id="land_single_feat" className="col-auto">
                <div>
                  <img src={img1} alt="" />
                </div>
                <div>100% Assured Placement</div>
              </div>
              <div id="land_single_feat" className="col-auto">
                <div>
                  <img src={img2} alt="" />
                </div>
                <div>Trained By Industry Experts</div>
              </div>
              <div id="land_single_feat" className="col-auto">
                <div>
                  <img src={img3} alt="" />
                </div>
                <div>Scholarship Opportunity</div>
              </div>
              <div id="land_single_feat" className="col-auto">
                <div>
                  <img src={img4} alt="" />
                </div>
                <div>Get Placed In Top MNC’s</div>
              </div>
              <div id="land_single_feat" className="col-auto">
                <div>
                  <img src={img5} alt="" />
                </div>
                <div>100% Money Back Guarantee*</div>
              </div>
              <div id="land_single_feat" className="col-auto">
                <div>
                  <img src={img6} alt="" />
                </div>
                <div>Earn While You Learn</div>
              </div>
              <div id="land_single_feat" className="col-auto">
                <div>
                  <img src={img7} alt="" />
                </div>
                <div>Get Experience Letter</div>
              </div>
            </div>

            <div id="land_last_line">
              <div className="row">
                <div className="col-md-6" align="start">
                  <div id="js">
                    <img src={js} alt="" />
                  </div>
                </div>
                <div className="col-md-6" align="end">
                  <div id="node_js">
                    <img src={nodejs} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* for mobiles */}
          <div className="container d-lg-none d-md-none d-block">
            <div id="mob_land_1st_line">
              <div className="row">
                <div
                  className="col-6"
                  align="left"
                  style={{ paddingLeft: "3.25rem" }}
                >
                  <img src={css} />
                </div>
                <div
                  className="col-6"
                  align="end"
                  style={{ paddingRight: "3.25rem" }}
                >
                  <img src={kotlin} />
                </div>
              </div>
            </div>
            <div id="mob_land_seat_ltd" align="center">
              <img src={land_fire} alt="" /> Only Limited Slots
            </div>
            <div className="d-flex" id="mob_second_line">
              <div>
                <img src={html} alt="" style={{ marginLeft: "0.5rem" }} />
              </div>
              <div id="mob_hero_text">
                <h1>
                  Best Full Stack Development Training Institute in Bangalore
                </h1>
              </div>
              <div>
                <img src={swift} alt="" />
              </div>
            </div>
            <p id="mob_land_sub_text">
              With 100% Placement Assured In Top <br />
              Tech Companies
            </p>
            <div id="mob_land_3rd_line">
              <div className="row">
                <div className="col-2">
                  <div id="mob_bootstrap_icon">
                    <img src={bootstrap} alt="" />
                  </div>
                </div>

                <div className="col-2" align="left">
                  <div id="mob_react_icon">
                    <img src={react} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div id="mob_land_cont_btn">
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="text-decoration-none text-white"
                >
                  <button className="btn">
                    <div> Consult Us Now</div>

                    <div className=" d-flex align-items-center justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M19.1618 12L12.9547 5.7929L11.5405 7.2071L16.3334 12L11.5405 16.7929L12.9547 18.2071L19.1618 12ZM13.5119 12L7.30483 5.7929L5.89062 7.2071L10.6835 12L5.89062 16.7929L7.30483 18.2071L13.5119 12Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </button>
                </a>
              </div>
            </div>{" "}
            <div id="mob_land_last_line">
              <div className="row">
                <div className="col-md-6" align="start">
                  <div id="mob_js">
                    <img src={js} alt="" />
                  </div>
                </div>
                <div className="col-md-6" align="end">
                  <div id="mob_node_js">
                    <img src={nodejs} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div id="mob_land_features" className="row w-100" align="center">
              <div id="mob_land_single_feat" className="col-auto w-100">
                <div>
                  <img src={img1} alt="" />
                </div>
                <div>100% Assured Placement</div>
              </div>
              <div id="mob_land_single_feat" className="col-auto w-100">
                <div>
                  <img src={img2} alt="" />
                </div>
                <div>Trained By Industry Experts</div>
              </div>
              <div id="mob_land_single_feat" className="col-auto w-100">
                <div>
                  <img src={img3} alt="" />
                </div>
                <div>Scholarship Opportunity</div>
              </div>
              <div id="mob_land_single_feat" className="col-auto w-100">
                <div>
                  <img src={img4} alt="" />
                </div>
                <div>Get Placed In Top MNC’s</div>
              </div>
              <div id="mob_land_single_feat" className="col-auto w-100">
                <div>
                  <img src={img5} alt="" />
                </div>
                <div>100% Money Back Guarantee*</div>
              </div>
              <div id="mob_land_single_feat" className="col-auto w-100">
                <div>
                  <img src={img6} alt="" />
                </div>
                <div>Earn While You Learn</div>
              </div>
              <div id="mob_land_single_feat" className="col-auto w-100">
                <div>
                  <img src={img7} alt="" />
                </div>
                <div>Get Experience Letter</div>
              </div>
            </div>
          </div>

          {/* for ipad */}
          <div className="container d-lg-none d-md-block d-none">
            <div id="land_1st_line">
              <div className="row">
                <div
                  className="col-6"
                  align="left"
                  style={{ paddingLeft: "9.5rem" }}
                >
                  <img src={css} style={{ height: "2rem", width: "2rem" }} />
                </div>
                <div
                  className="col-6"
                  align="end"
                  style={{ paddingRight: "10rem" }}
                >
                  <img src={kotlin} style={{ height: "2rem", width: "2rem" }} />
                </div>
              </div>
            </div>
            <div id="land_seat_ltd" align="center">
              <img src={land_fire} alt="" /> Only Limited Slots
            </div>
            <div id="land_2nd_line">
              <div className="row" align="center">
                <div className="col-md-1">
                  <img
                    src={html}
                    alt=""
                    width="100%"
                    style={{ height: "2rem", width: "2rem" }}
                  />
                </div>
                <div className="col-md-10" id="land_hero_text">
                  <h1
                    style={{
                      fontSize: "2.5rem",
                      fontWeight: "800",
                      lineHeight: "3.25rem",
                    }}
                  >
                    Best Full Stack Development Training Institute in Bangalore
                  </h1>
                </div>

                <div className="col-md-1">
                  <img
                    src={swift}
                    alt=""
                    width="100%"
                    style={{ height: "2rem", width: "2rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div id="bootstrap_icon" className="col-2" style={{}}>
                <img
                  src={bootstrap}
                  alt=""
                  style={{
                    height: "1.59rem",
                    width: "2rem",
                    marginLeft: "3rem",
                  }}
                />
              </div>
              <p
                id="land_sub_text"
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  marginTop: "2rem",
                }}
                className="col-8"
              >
                With 100% Placement Assured In Top Tech Companies
              </p>
              <div className="col-2" align="left">
                <div id="react_icon">
                  <img
                    src={react}
                    alt=""
                    style={{ height: "2rem", width: "2rem" }}
                  />
                </div>
              </div>
            </div>
            <div id="land_3rd_line">
              <div id="land_cont_btn">
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="text-decoration-none text-white"
                >
                  <button className="btn">
                    <div> Consult Us Now</div>

                    <div className=" d-flex align-items-center justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M19.1618 12L12.9547 5.7929L11.5405 7.2071L16.3334 12L11.5405 16.7929L12.9547 18.2071L19.1618 12ZM13.5119 12L7.30483 5.7929L5.89062 7.2071L10.6835 12L5.89062 16.7929L7.30483 18.2071L13.5119 12Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </button>
                </a>
              </div>
            </div>
            <div id="land_last_line">
              <div className="row">
                <div className="col-md-6" align="start">
                  <div id="js">
                    <img src={js} alt="" style={{width:"2rem", height:"2rem"}}/>
                  </div>
                </div>
                <div className="col-md-6" align="end">
                  <div id="node_js">
                    <img src={nodejs} alt="" style={{width:"2rem", height:"2.26rem"}} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row w-100" align="center">
              <div className="col-6">
                <div id="land_single_feat">
                  <div>
                    <img src={img1} alt="" />
                  </div>
                  <div>100% Assured Placement</div>
                </div>
              </div>
              <div className="col-6 ">
                <div id="land_single_feat">
                <div>
                  <img src={img2} alt="" />
                </div>
                <div>Trained By Industry Experts</div>
                </div>
              </div>
              <div className="col-6 ">
                <div id="land_single_feat">
                <div>
                  <img src={img3} alt="" />
                </div>
                <div>Scholarship Opportunity</div>
                </div>
              </div>
              <div className="col-6 ">
                <div id="land_single_feat">
                <div>
                  <img src={img4} alt="" />
                </div>
                <div>Get Placed In Top MNC’s</div>
                </div>
              </div>
              <div className="col-6 ">
                <div id="land_single_feat">
                <div>
                  <img src={img5} alt="" />
                </div>
                <div>100% Money Back Guarantee*</div>
                </div>
              </div>
              <div className="col-6 ">
                <div id="land_single_feat">
                <div>
                  <img src={img6} alt="" />
                </div>
                <div>Earn While You Learn</div>
                </div>
              </div>
              <div className="col-12 " align="center">
                <div id="land_single_feat">
                <div>
                  <img src={img7} alt="" />
                </div>
                <div>Get Experience Letter</div>
                </div>
              </div>
              
            </div>
            

           
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
